import Circle from "ol/geom/Circle";
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { Circle as CircleStyle, Stroke, Style, Icon, Fill, Text } from "ol/style";
import { OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {fromLonLat} from 'ol/proj';
import {getDistance} from 'ol/sphere';
import {boundingExtent} from "ol/extent";
import {getCenter} from 'ol/extent';
import {Control, defaults as defaultControls} from 'ol/control';

import Stamen from 'ol/source/Stamen';

let parser_future = new Promise(function(resolve, reject) {
    fetch("https://docs.google.com/spreadsheets/d/1WdoqfeTq0GUWiSM4ztghrNL-aZxYdFufnD7pH0SkJxg/gviz/tq?tqx=responseHandler:")
      .then(response => response.text())
      .then(result => {
          const parsedJSON = JSON.parse(result.split('\n')[1].replace(/google.visualization.Query.setResponse\(|\);/g, ''))
          // Assumes the target value is in row 0, column 2 (excluding
          // header)
          const value = parsedJSON.table.rows[0].c[2].v;
          //let start = parsedJSON.table.rows[0].c[0].v;
          console.log("Total value: " + value);
          resolve([{"total":value}]);
      });
});

const styles = {
  Done: new Style({
    stroke: new Stroke({
      color: "green",
      width: 4
    })
  }),
  NotDone: new Style({
    stroke: new Stroke({
      color: [255, 0, 0, 0.5],
      width: 4
    })
  }),
};

const textStyles = {
    Venice: new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({color: "green"}),
        }),
        text: new Text({
            font: '20px sans-serif',
            text: 'Venice',
            textAlign: 'left',
            offsetX: 8,
            stroke: new Stroke({
                color: "white",
                width: 3,
            }),
        }),
    }),
    Villapiana: new Style({
        image: new CircleStyle({
            radius: 5,
            fill: new Fill({color:  [255, 0, 0, 0.5]}),
        }),
        text: new Text({
            font: '20px sans-serif',
            text: 'Villapiana',
            textAlign: 'right',
            offsetX: -8,
            stroke: new Stroke({
                color: "white",
                width: 3,
            }),
        }),
    }),
};

const imageUrl = new URL('data/caticon.png?as=jpg&width=300', import.meta.url);

const iconStyle = new Style({
  image: new Icon({
    anchor: [0, 0],
    anchorOrigin: 'bottom-left',
    src: imageUrl.pathname,
    rotateWithView: true,
  }),
    /*
  text: new Text({
      font: '12px sans-serif',
      text: '335k',
      textAlign: 'center',
      offsetX: 15,
      offsetY: -8,
      stroke: new Stroke({
          color: "white",
          width: 3,
      }),
  }),
  */
});

let iconFeature = new Feature(new Point([0.5, 0.5]));

iconFeature.set('style', iconStyle);

const styleFunction = function (feature) {
  return styles[feature.get("name")];
};


const vectorSource = new VectorSource();

let doneLineString = new LineString([]);
let notDoneLineString = new LineString([]);

let iconPlaced = false;

const iconLayer = new VectorLayer({
  source: new VectorSource({
      features: [iconFeature],
    }),
    style: function(feature, resolution) {
        const scale = 3 * 1/Math.pow(resolution, 1/3);
        iconStyle.getImage().setScale(scale);
        //iconStyle.getText().setScale(6*scale);
        return iconStyle;
    },
});

const startPoint = new Feature({
    geometry: new Point([0,0]),
    name: "Venice",
});
const stopPoint = new Feature({
    geometry: new Point([0,0]),
    name: "Villapiana",
});

const startStopLayer = new VectorLayer({
    source: new VectorSource({
        features: [startPoint, stopPoint],
    }),
    style: function(feature, resolution) {
        return textStyles[feature.get("name")];
    }
});

const daysPoint = new Feature(new Point([0,0]));
const daysLayer = new VectorLayer({
    source: new VectorSource({
        features: [daysPoint],
    }),
    style: function(feature, resolution) {
        console.log("Days layer " + resolution);
    },
});

import tracks from './tracks.json';
console.log("track length: " +  tracks["features"][0]["geometry"]["coordinates"][0].length)
parser_future.then((items) => {
    class DaysControl extends Control {
        constructor(opt_options) {
            const options = opt_options || {};
                const button = document.createElement('button');

                let start = Date.parse("2023-04-01");
                let now = Date.now();
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

                const diffDays = Math.ceil(Math.abs((now - start) / oneDay));
                button.innerHTML = diffDays;

                const element = document.createElement('div');
                element.className = 'days ol-unselectable ol-control';
                element.appendChild(button);

                super({
                  element: element,
                  target: options.target,
                });

                button.addEventListener('click', this.handleRotateNorth.bind(this), false);
            }

          handleRotateNorth() {
            this.getMap().getView().setRotation(0);
          }
    }

    let meters = 0;
    let completed = 0;
    if(items === null) {
        console.log("Error in fetch");
    }
    else {
        completed = items[0]["total"]*1000;
    }
    console.log("Total " + completed );

    let track_length = tracks["features"][0]["geometry"]["coordinates"][0].length;
    for(let p = 0; p < track_length; p++) {
        let curCoord = tracks["features"][0]["geometry"]["coordinates"][0][p];
        if(p == 0) {
            startPoint.setGeometry(new Point(fromLonLat(curCoord)));
        }
        if(p == track_length-1) {
            stopPoint.setGeometry(new Point(fromLonLat(curCoord)));
        }
        if(p > 0)
        {
            let prevCoord = tracks["features"][0]["geometry"]["coordinates"][0][p-1];
            meters += getDistance(prevCoord, curCoord);
        }
        if(meters < completed)
        {
            doneLineString.appendCoordinate(fromLonLat(curCoord));
        }
        else
        {
            if (!iconPlaced)
            {
                iconFeature.setGeometry(new Point(fromLonLat(curCoord)));
                // join the two paths
                doneLineString.appendCoordinate(fromLonLat(curCoord));
                iconPlaced = true;
            }
            notDoneLineString.appendCoordinate(fromLonLat(curCoord));
        }
    }
    vectorSource.addFeature(new Feature({
        geometry: doneLineString,
        name: "Done"
    }));
    vectorSource.addFeature(new Feature({
        geometry: notDoneLineString,
        name: "NotDone"
    }));
    console.log("Meters: " + meters + " Completed " + completed)

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: styleFunction
    });

    let view = new View({
        center: fromLonLat([-3, 54.51]),
        zoom: 10
      });
    let first = fromLonLat(tracks["features"][0]["geometry"]["coordinates"][0][0]);
    let last = fromLonLat(tracks["features"][0]["geometry"]["coordinates"][0][track_length-1]);
    let extent = boundingExtent([first, last]);
    const map = new Map({
      controls: defaultControls().extend([new DaysControl()]),
      layers: [
        new TileLayer({
          source: new Stamen({
            layer: 'watercolor',
          }),
        }),
        new TileLayer({
          source: new Stamen({
            layer: 'terrain-labels',
          }),
        }),
        vectorLayer,
        iconLayer,
        startStopLayer,
        daysLayer,
      ],
      target: "map",
      view: view
    });

    view.fit(extent, {padding: [100, 100, 100, 100]});

    },
    (error) => {
        console.log("Error: " + error);
    }
);
