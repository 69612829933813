/**
 * @module ol/style/IconOrigin
 */

/**
 * Icon origin. One of 'bottom-left', 'bottom-right', 'top-left', 'top-right'.
 * @enum {string}
 */
export default {
  /**
   * Origin is at bottom left
   * @api
   */
  BOTTOM_LEFT: 'bottom-left',
  /**
   * Origin is at bottom right
   * @api
   */
  BOTTOM_RIGHT: 'bottom-right',
  /**
   * Origin is at top left
   * @api
   */
  TOP_LEFT: 'top-left',
  /**
   * Origin is at top right
   * @api
   */
  TOP_RIGHT: 'top-right',
};
