/**
 * @module ol/interaction/Property
 */

/**
 * @enum {string}
 */
export default {
  ACTIVE: 'active',
};
